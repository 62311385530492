import React from 'react';
import { RedocStandalone } from 'redoc';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

class ApiDocs extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <RedocStandalone
          specUrl="/openapi.yaml"
          options={{
            nativeScrollbars: true,
            theme: { colors: { main: '#dd5522' } },
            noAutoAuth: true,
            expandResponses: "200,201",
            lazyRendering: true,
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ApiDocs);
