import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Button from '@material-ui/core/Button';
import { Timer, Code } from '@material-ui/icons';

import styles from './styles';

class Home extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classNames(classes.buttons)}>
          <GridContainer>
            <GridItem md={6} className={classNames(classes.buttonContainer)}>
              <Button
                href="https://sakari.io/blog/text-messaging-sms-api-quickstart/"
                color="transparent"
                className={classNames(classes.button)}
              >
                <div>
                  <Timer className={classNames(classes.icon)}/>
                  <div style={{ fontWeight: 'bold' }}>Quickstarts</div>
                  <div>
                    Quick and simple tutorials to get to up and running
                  </div>
                </div>
              </Button>
            </GridItem>

            <GridItem md={6} className={classNames(classes.buttonContainer)}>
              <Button
                href="/docs"
                color="transparent"
                className={classNames(classes.button)}
              >
                <div>
                  <Code className={classNames(classes.icon)}/>
                  <div style={{ fontWeight: 'bold' }}>API Documentation</div>
                  <div>
                    OpenAPI 3 compliant documentation detailing endpoints, payload requirements and typical responses
                  </div>
                </div>
              </Button>
            </GridItem>

            {/*<GridItem md={6} className={classNames(classes.buttonContainer)}>*/}
              {/*<Button*/}
                {/*href="https://www.creative-tim.com/product/material-kit-react"*/}
                {/*color="transparent"*/}
                {/*className={classNames(classes.button)}*/}
              {/*>*/}
                {/*<div>*/}
                  {/*<HelpOutline className={classNames(classes.icon)}/>*/}
                  {/*<div style={{ fontWeight: 'bold' }}>Helper Libraries</div>*/}
                  {/*<div>*/}
                    {/*Client SDKs written in a number of different languages to simplify integration even further*/}
                  {/*</div>*/}
                {/*</div>*/}
              {/*</Button>*/}
            {/*</GridItem>*/}
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Home);
