/* eslint-disable */
import React from 'react';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';

// material-ui components
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import { Apps, CloudDownload, Lock } from '@material-ui/icons';

// core components
// import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from '../../CustomButtons/Button';
import IconButton from '../../CustomButtons/IconButton';

import headerLinksStyle from './style';

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      {/*<ListItem className={classes.listItem}>*/}
      {/*<CustomDropdown*/}
      {/*buttonText="Components"*/}
      {/*buttonProps={{*/}
      {/*className: classes.navLink,*/}
      {/*color: "transparent"*/}
      {/*}}*/}
      {/*buttonIcon={Apps}*/}
      {/*dropdownList={[*/}
      {/*<Link to="/" className={classes.dropdownLink}>*/}
      {/*All components*/}
      {/*</Link>,*/}
      {/*// eslint-disable-next-line*/}
      {/*<a*/}
      {/*href="http://creativetimofficial.github.io/material-kit-react/#/documentation"*/}
      {/*target="_blank"*/}
      {/*className={classes.dropdownLink}*/}
      {/*>*/}
      {/*Documentation*/}
      {/*</a>*/}
      {/*]}*/}
      {/*/>*/}
      {/*</ListItem>*/}
      <ListItem className={classes.listItem}>
        <Button
          href="https://hub.sakari.io"
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <Lock className={classes.icons}/> Login
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="https://hub.sakari.io/signup"
          color="transparent"
          target="_blank"
          className={classes.navLink}
          round
        >
          Register
        </Button>
      </ListItem>
      {/*<ListItem className={classes.listItem}>*/}
        {/*<Tooltip*/}
          {/*id="instagram-twitter"*/}
          {/*title="Follow us on twitter"*/}
          {/*placement={window.innerWidth > 959 ? 'top' : 'left'}*/}
          {/*classes={{ tooltip: classes.tooltip }}*/}
        {/*>*/}
          {/*<IconButton*/}
            {/*href="https://twitter.com/CreativeTim"*/}
            {/*target="_blank"*/}
            {/*color="transparent"*/}
            {/*className={classes.navLink + ' ' + classes.socialIconsButton}*/}
          {/*>*/}
            {/*<i className={classes.socialIcons + ' fab fa-twitter'}/>*/}
          {/*</IconButton>*/}
        {/*</Tooltip>*/}
      {/*</ListItem>*/}
      {/*<ListItem className={classes.listItem}>*/}
        {/*<Tooltip*/}
          {/*id="instagram-facebook"*/}
          {/*title="Follow us on facebook"*/}
          {/*placement={window.innerWidth > 959 ? 'top' : 'left'}*/}
          {/*classes={{ tooltip: classes.tooltip }}*/}
        {/*>*/}
          {/*<IconButton*/}
            {/*color="transparent"*/}
            {/*href="https://www.facebook.com/CreativeTim"*/}
            {/*target="_blank"*/}
            {/*className={classes.navLink + ' ' + classes.socialIconsButton}*/}
          {/*>*/}
            {/*<i className={classes.socialIcons + ' fab fa-facebook'}/>*/}
          {/*</IconButton>*/}
        {/*</Tooltip>*/}
      {/*</ListItem>*/}
      {/*<ListItem className={classes.listItem}>*/}
        {/*<Tooltip*/}
          {/*id="instagram-tooltip"*/}
          {/*title="Follow us on instagram"*/}
          {/*placement={window.innerWidth > 959 ? 'top' : 'left'}*/}
          {/*classes={{ tooltip: classes.tooltip }}*/}
        {/*>*/}
          {/*<IconButton*/}
            {/*color="transparent"*/}
            {/*href="https://www.instagram.com/CreativeTimOfficial"*/}
            {/*target="_blank"*/}
            {/*className={classes.navLink + ' ' + classes.socialIconsButton}*/}
          {/*>*/}
            {/*<i className={classes.socialIcons + ' fab fa-instagram'}/>*/}
          {/*</IconButton>*/}
        {/*</Tooltip>*/}
      {/*</ListItem>*/}
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
