import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Home from './views/Home';
import ApiDocs from './views/ApiDocs'
import Header from './components/Header';
import HeaderLinks from './components/Header/HeaderLinks';
import Parallax from './components/Parallax';
import GridContainer from './components/Grid/GridContainer';
import GridItem from './components/Grid/GridItem';
import './App.css';

import { container } from './assets/material-kit-react.jsx';

const styles = {
  container,
  brand: {
    color: "#FFFFFF",
    textAlign: "left"
  },
  title: {
    fontSize: "3.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative"
  },
  subtitle: {
    fontSize: "1.6rem",
    maxWidth: "500px",
    margin: "0px 0 0"
  },
}

function App({classes}) {
  return (<div>
    <Header
      brand={<img src="https://assets.sakari.io/images/sakari-logo-w.svg" width="150" className="d-lg-none"
                  alt="Sakari Logo"/>}
      rightLinks={<HeaderLinks/>}
      fixed
      color="transparent"
      changeColorOnScroll={{
        height: 400,
        color: 'white'
      }}
    />

    <Parallax image={require('./assets/img/bg5.jpg')}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem>
            <div className={classes.brand}>
              <h1 className={classes.title}>Sakari Developer Portal</h1>
              <h2 className={classes.subtitle}>
                Bulk text messaging for business.
              </h2>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Parallax>

    <Router>
      <Switch>
        <Route path="/" key="home" component={Home} exact/>
        <Route path="/docs" key="apidocs" component={ApiDocs}/>
      </Switch>
    </Router>
  </div>);
}

export default withStyles(styles)(App);
